import PropTypes from 'prop-types';
import TextDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/TextDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getContentWrapperStyles from '@activebrands/core-web/utils/style-functions/get-content-wrapper-styles';

const TextModule = ({ isGrid = false, ...rest }) => {
    const [css] = useStyletron();
    const contentWrapperStyles = getContentWrapperStyles();

    return (
        <section
            className={css({
                marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)',
                ...contentWrapperStyles,
            })}
        >
            <TextDataTransformerBlock {...rest} />
        </section>
    );
};

TextModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default TextModule;
